/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                objectFitImages();
                var viewportWidth = $(window).width();
                var viewportHeight = $(document).height();
                var content = 'html,body';
                if(viewportWidth < 768){
                    content = '.page-wrap';
                }

                // Smmoth scrolling script. Scrollable area depends on screensize.
                $('a[href*="#"]:not([href="#"])').click(function () {
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            if(viewportWidth > 768 || target.selector === '#top') {
                                $(content).animate({
                                  scrollTop: target.offset().top
                                }, 1000);
                                return false;
                            } else {
                                var main = $(".page-wrap:not(:animated)");
                                var destination = $(target).offset().top + main.scrollTop();
                                main.animate({ scrollTop: destination },500);
                                return false;
                            }
                        }
                    }
                });


                // Override smooth scroll script settings from beaverbuilder
                if ( 'undefined' !== typeof FLBuilderLayoutConfig ) {
                    FLBuilderLayoutConfig.anchorLinkAnimations.duration = 1000;
                    FLBuilderLayoutConfig.anchorLinkAnimations.easing = 'swing';
                    FLBuilderLayoutConfig.anchorLinkAnimations.offset = 0;
                }


                // Close old browser warning in IE lt 10 on click
                $('.close-oldie').click(function(){
                    $( ".oldie-warning" ).fadeOut();
                });


                // Toggles specific elements on specific scroll position
                $.fn.toggleElementsOnScroll = function() {
                    var y = $(this).scrollTop();
                    if (y > viewportHeight/2) {
                        $('.to-top').removeClass('hidden');
                        $('.floaty-wrap').removeClass('hidden');
                    } else {
                        $('.to-top').addClass('hidden');
                        $('.floaty-wrap').addClass('hidden');
                    }
                };


                // MediaQuery triggered functions - different scroll container for mobile & desktop
                var mqDesktop = Modernizr.mq('(min-width: 768px)');
                if (mqDesktop) {

                    // Scroll-Top-Btn / Floaty-Bar fading function
                    $(document).scroll(function() {
                        $(this).toggleElementsOnScroll();
                    });

                }
                else {
                    // Scroll-Top-Btn / Floaty-Bar fading function
                    var lastScrollPos = 0;
                    var topbar = $('.topbar');
                    var topbarHeight = topbar.outerHeight();

                    $('.page-wrap').scroll(function() {
                        $(this).toggleElementsOnScroll();

                        var scrollPos = $(this).scrollTop();
                        if(scrollPos > lastScrollPos){
                            if(scrollPos > viewportHeight/14) {
                                topbar.addClass('slide-up');
                            }
                            else {
                                topbar.removeClass('slide-up');
                            }
                        } else {
                            topbar.removeClass('slide-up');
                        }
                        lastScrollPos = scrollPos;
                    });
                }


                // Initialize doubletaptogo on desktop-nav for dropdown touch support
                if($('html').hasClass('touchevents')) {
                    $( '#desktop-nav li:has(ul)' ).doubleTapToGo();
                }

                // IBE Widget Toggle
                $('.ibe-toggle').click(function() {
                  $(this).toggleClass('active');
                  $(this).siblings('#form-booking').toggleClass('form-open');
                });

                // modified from same-domain version at www.dyn-web.com/tutorials/iframes/height/
                function setIframeHeightCO(id, ht) {
                    var ifrm = document.getElementById(id);
                    ifrm.style.visibility = 'hidden';
                    // some IE versions need a bit added or scrollbar appears
                    ifrm.style.height = ht + 4 + "px";
                    ifrm.style.visibility = 'visible';
                }


                // iframed document sends its height using postMessage
                function handleDocHeightMsg(e) {
                    // check origin
                    if ( e.origin === 'https://www.caesar-data.com' ) {
                        // parse data
                        var height = e.data;
                        console.log(height);
                        // set iframe height
                        setIframeHeightCO( 'bookingFrame', height );
                    }
                }

                // assign message handler
                if ( window.addEventListener ) {
                    window.addEventListener('message', handleDocHeightMsg, false);
                } else if ( window.attachEvent ) { // ie8
                    window.attachEvent('onmessage', handleDocHeightMsg);
                }

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
